import React, { useState, useEffect } from "react";
import {Card, Table, Button, Modal, Form, Input, Select, Upload, message, Image, Skeleton  } from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined, UploadOutlined } from "@ant-design/icons";
import axiosInstance from "../../../axiosInterceptor";
import styled from "styled-components";

const { Option } = Select;

const EditarLinks = () => {

  const accessToken = window.localStorage.getItem("accessToken");
  const idUser = window.localStorage.getItem("idUser");
  const [idLoja, setIDLoja] = useState(null);
  const [loading, setLoading] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);
  const [form] = Form.useForm();
  const [loja, setLoja] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);


  const [modalVisible, setModalVisible] = useState(false);


  useEffect(() => {
    if (idUser) {
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      };
      axiosInstance.get(
        `https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`,
        options
      ).then((resp) => {
        setIDLoja(resp.data.id);
        setLoja(resp.data);
      });
    }
  }, [idUser]);

  useEffect(() => {
    if (idUser) {
        const options = {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + accessToken,
            },
          };
      axiosInstance.get(`https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`, options)
        .then(resp => setIDLoja(resp.data.id));
    }
  }, [idUser]);

  const loadDados = () => {
    setLoading(true);

    const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      };

axiosInstance.get(`https://api.pedtech.com.br/v1/pedtech/getByEmpresa/${idLoja}`, options)
  .then(resp => {
    try {
      const data = resp.data?.SocialInfos || {};
      console.log("Dados recebidos:", data);

      // Converte a string JSON para um array, caso seja necessário
      const links = data.linksList ? JSON.parse(data.linksList) : [];

      console.log("Links processados:", links);
      setLinks(links);
    } catch (error) {
      console.error("Erro ao processar os links:", error);
      setLinks([]);
    } finally {
      setLoading(false);
    }
  })
  .catch(error => {
    console.error("Erro ao buscar os links:", error);
    setLinks([]); // Garante que o estado seja um array vazio em caso de erro
    setLoading(false);
  });
}


  useEffect(() => {
    setLoading(true);

    if (idUser && idLoja) {
      loadDados();
    }
  }, [idUser, accessToken, idLoja]);


  const handleSaveBanco = (json) => {
    console.log(json);
   

    const jsonData = {
      linksList: JSON.stringify(json) // Converte o objeto para string corretamente
    };
  
    console.log(JSON.stringify(jsonData));
    try {
      setLoading(true);
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      };

      axiosInstance.put(
        `https://api.pedtech.com.br/v1/pedtech/links/`+idLoja, jsonData,
        options
      ).then((resp) => {
        if (resp.status == 200) {
          message.success('Conteudo salvo!');
        }
        setLoading(false);
      });

    } catch (error) {
      console.error('Erro ao chamar a API:', error.status);
      if (error.status === 404) {
        message.error('Erro ao gravar!');
      }
      setLoading(false);
    }
    
  };


  const [links, setLinks] = useState([])

  /*const [links, setLinks] = useState([
    {
      "IsLinkOrItem": "link",
      "redesociais": {
        "redesocial": "Whatsapp",
        "link": "https://api.whatsapp.com/send?1=pt_BR&phone=5511963697271"
      },
      "ItemImageSrc": null,
      "ItemText": null
    },
                {
      "IsLinkOrItem": "link",
      "redesociais": {
        "redesocial": "Whatsapp",
        "link": "https://api.whatsapp.com/send?1=pt_BR&phone=5511963697271"
      },
      "ItemImageSrc": null,
      "ItemText": null
    },
    {
      "IsLinkOrItem": "item",
      "redesociais": {
        "redesocial": "Whatsapp",
        "link": "https://api.whatsapp.com/send?1=pt_BR&phone=5511963697271"
      },
      "ItemImageSrc": "/Capa1.jpg",
      "ItemText": "Faça Seu Pedido"
    }
]);*/


const handleUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      setPreviewImage(e.target.result); // Armazena a imagem como base64
    };
    reader.readAsDataURL(file);
    return false; // Previne o upload automático do Ant Design
  };


  const handleEdit = (index) => {
    setEditingIndex(index);
    form.setFieldsValue(links[index]);
    setPreviewImage(links[index].ItemImageSrc); // Atualiza a prévia da imagem
    setModalVisible(true);
  };

  const handleDelete = (index) => {
    const updatedLinks = links.filter((_, i) => i !== index);
    setLinks(updatedLinks);
    message.success("Item removido com sucesso!");
  
    console.log("Após excluir:", JSON.stringify(updatedLinks, null, 2));
  };

  const handleAdd = () => {
    setEditingIndex(null);
    form.resetFields();
    setPreviewImage(null); // Limpa a imagem ao adicionar novo produto
    setModalVisible(true);
    console.log("Novo item adicionado, estado atual:", JSON.stringify(links, null, 2));

  };



  
  const handleSave = () => {
    form.validateFields().then((values) => {
      const newData = {
        IsLinkOrItem: values.IsLinkOrItem,
        redesociais: {
          redesocial: values.redesociais.redesocial,
          link: values.redesociais.link
        },
        ItemImageSrc: previewImage, // Agora salva a imagem base64 corretamente
        ItemText: values.ItemText || null
      };
  
      let updatedLinks;
      if (editingIndex !== null) {
        updatedLinks = [...links];
        updatedLinks[editingIndex] = newData;
      } else {
        updatedLinks = [...links, newData];
      }
  
      setLinks(updatedLinks);
      setModalVisible(false);
      form.resetFields();
      setPreviewImage(null);
  
      console.log("Após salvar/modificar:", JSON.stringify(updatedLinks, null, 2));
    });
  };
  
  const columns = [
    {
        title: "Ações",
        key: "actions",
        render: (_, record, index) => (
          <>
            <Button icon={<EditOutlined />} onClick={() => handleEdit(index)} size="small" />
            <Button icon={<DeleteOutlined />} danger onClick={() => handleDelete(index)} size="small" />
          </>
        )
      },
    {
      title: "Link",
      dataIndex: ["redesociais", "link"],
      key: "link",
      render: (text) => <a href={text} target="_blank" rel="noopener noreferrer">{text}</a>
    },


  ];

  return (
    <Container>
      <Card style={{ width: "90%", marginTop: "140px", marginBottom: "110px" }}
      bodyStyle={{ padding: 0 }}>
                <h1
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          Configuração de Links
        </h1>

        {loading ? (
              <Skeleton active />
            ) : (
        <div style={{ padding: 20 }}>
          <Button type="primary" icon={<PlusOutlined />} onClick={handleAdd} style={{ marginBottom: 16 }}>
            Adicionar Novo
          </Button>

          <Button type="primary" icon={<PlusOutlined />} onClick={() => handleSaveBanco(links)} style={{ marginBottom: 16 }}>
            Salvar Links
          </Button>


          <Table 
            columns={columns} 
            dataSource={links} 
            rowKey={(_, index) => index} 
            pagination={false} 
            scroll={{ x: "max-content" }}
            style={{ border: "none", margin: 0, padding: 0 }} 
            />
          <Modal 
            title={editingIndex !== null ? "Editar Link/Item" : "Adicionar Link/Item"} 
            open={modalVisible} 
            onCancel={() => setModalVisible(false)} 
            onOk={handleSave}
          >
            <Form form={form} layout="vertical">
              <Form.Item name="IsLinkOrItem" label="Tipo" rules={[{ required: true, message: "Selecione um tipo!" }]}>
                <Select>
                  <Option value="link">Link</Option>
                  <Option value="item">Item</Option>
                </Select>
              </Form.Item>


              <Form.Item
              name={["redesociais", "redesocial"]}
              label="Rede Social (tipo link)"
              rules={[]}
              initialValue="Whatsapp" // Define a opção padrão
            >
              <Select>
                {["Whatsapp", "Instagram", "Facebook", "Youtube", "TechsApp", "Twitter", "Telefone"].map((option) => (
                  <Select.Option key={option} value={option}>
                    {option}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>


              <Form.Item name={["redesociais", "link"]} label="Link" rules={[{ required: true, message: "Informe o link!" }]}>
                <Input />
              </Form.Item>
              <Form.Item name="ItemText" label="Texto (tipo item)">
                <Input />
              </Form.Item>
              <Form.Item name="ItemImageSrc" label="Imagem (tipo item)">
            <Upload beforeUpload={handleUpload} listType="picture">
                <Button icon={<UploadOutlined />}>Upload</Button>
            </Upload>
            {previewImage && <Image width={100} src={previewImage} style={{ marginTop: 10 }} />}
            </Form.Item>
            </Form>
          </Modal>
        </div>
            )}
      </Card>
    </Container>
  );
};



const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export default EditarLinks;
