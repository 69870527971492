import React, { useState, useEffect, useCallback } from "react";
import { Tabs } from "antd";

import {
  UserOutlined,
  HomeOutlined,
  ReadOutlined,
  TeamOutlined,
  LogoutOutlined,
} from "@ant-design/icons";

import Home from "./primeiraSessao/Home";
import HomeTextos from "./primeiraSessao/HomeTextos";
import EditarLinks from "./primeiraSessao/EditarLinks";


import styled from "styled-components";
import { Link, useHistory, useParams } from "react-router-dom";
import Sair from "./Sair";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  fa1,
  fa2,
  fa3,
  faArrowUp91,
  faCircleInfo,
  faGear,
  faHome,
  faImage,
  faList,
  faRightFromBracket,
  faShop,
  faTemperature1,
  faThumbsUp,
  faTurnDown,
  faTurnUp,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import "./TabStyles.css";
import { logout } from "../../Token/actions";
import { useDispatch } from "react-redux";
import Perfil from "./perfil/Perfil";
import VisualizarPerfil from "./perfil/VisualizarPerfil";
import AlterarSenhaScreen from "./perfil/AlterarSenhaScreen";
import FormularioHabitualidade from "./habitualidades/FormularioHabitualidade";
import CameraModal from "./habitualidades/CameraModal";
import PageHeader from "./pageHeader/PageHeader";
import axiosInstance from "../../axiosInterceptor";
import jwtDecode from "jwt-decode";
import Sobre from "./habitualidades/Sobre";
import RedesPrimeiraSessao from "./socialButtons/SocialButtons";
import RedesRodape from "./socialButtons/SocialButtonsFooter";


import RodapeCores from "./rodape/CoresRodape"
import ConteudoRodape from "./rodape/ConteudoRodape"

import HeaderColors from "./header/HeaderColors"
import HeaderLinks from "./header/HeaderLinks"
import SecondSessionColors from "./segundaSessao/SecondSessionColors";
import SecondSessionContent from "./segundaSessao/SecondSessionContent";
import ThirdSessionColors from "./terceiraSessao/ThirdSessionColors";
import ThirdSessionContents from "./terceiraSessao/ThirdSessionContents";
import TagsForm from "./socialButtons/TagsForm"



const { TabPane } = Tabs;
const TopTab = () => {
  const history = useHistory();
  const [activeKey, setActiveKey] = useState("1");
  const { tab } = useParams();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("subTab1");
  const [activeTabPerfil, setActiveTabPerfil] = useState("subTab1");

  const [activeTabHome, setActiveTabHome] = useState("subTabHome1");

  const [activeTabClube, setActiveTabClube] = useState("subTabClube1");

  const [activeTabRedes, setActiveTabRedes] = useState("subTabRedes1");


  const [tabName, setTabName] = useState("Início");
  const [recarregarDadosHabitualidades, setRecarregarDadosHabitualidades] =
    useState(false);
  const [recarregarDadosClube, setRecarregarDadosClube] = useState(false);

  const [recarregarDadosPerfil, setRecarregarDadosPerfil] = useState(false);
  const [recarregarDadosInicio, setRecarregarDadosInicio] = useState(false);
  const [arrayContatos, setArrayContatos] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });

  const [activeTabHabitualidade, setActiveTabHabitualidade] = useState(
    "subTabHabitualidade1"
  );
  const [
    carregarUsuarioFormHabitualidade,
    setCarregarUsuarioFormHabitualidade,
  ] = useState(false);
  const [carregarPerfil, setCarregarPerfil] = useState(false);
  const [carregarClube, setCarregarClube] = useState(false);
  const [qtdSelecionados, setQtdSelecionados] = useState(0);
  const [loja, setLoja] = useState(null);
  const idUser = window.localStorage.getItem("idUser");
  const accessToken = window.localStorage.getItem("accessToken");

  const [nomeUsuarioLogado,setNomeUsuarioLogado] = useState('');
  const [nomeLoja,setNomeLoja] = useState('');


  useEffect(() => {
    if (!idUser) return;
  
    const fetchData = async () => {
      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + accessToken,
        },
      };
      if (!loja) {
        const resp = await axiosInstance.get(`https://techfood-api.herokuapp.com/v1/restaurantes/usuario/${idUser}`, options);
        setNomeLoja(resp.data.nome);
        setNomeUsuarioLogado(jwtDecode(accessToken).nome_completo);
      }
    };
    fetchData();
  }, [idUser]);

  useEffect(() => {
    if (tab === "2" || tab === "4") {
      if (tab === "2") {
        setTabName("Habitualidade");
      }
      if (tab === "4") {
        setTabName("Contatos");
      }

      setActiveKey(tab);
    }
  }, [tab]);

  

  const updateSetQtdSelecionados = (qtd) => {
    setQtdSelecionados(qtd);
  };

  const handleRecarregarDadosHabitualidades = () => {
    setRecarregarDadosHabitualidades(!recarregarDadosHabitualidades);
  };
  const handleRecarregarDadosPerfil = () => {
    setRecarregarDadosPerfil(!recarregarDadosPerfil);
  };

  const handleRecarregarDadosClube = () => {
    setRecarregarDadosClube(!recarregarDadosClube);
  };

  const handleButtonClick = () => {
    setActiveTab("subTab3");
  };

  const handleTabChange = (key) => {
    setActiveTab(key);
  };



  const handleTabChangeHome = (key) => {
    setActiveTabHome(key);
    console.log(key);
    /*handleRecarregarDadosPerfil();
    if (key === "subTab2") {
      setCarregarPerfil(!carregarPerfil);
    }*/
  };



  const handleTabChangePerfil = (key) => {
    setActiveTabPerfil(key);
    handleRecarregarDadosPerfil();
    if (key === "subTab2") {
      setCarregarPerfil(!carregarPerfil);
    }
  };
  const handleTabChangeClube = (key) => {
    setActiveTabClube(key);

    if (key === "subTabClube1") {
      handleRecarregarDadosClube();
    }
  };


  const handleTabChangeRedes = (key) => {
    
    setActiveTabRedes(key);
    
    /*if (key === "subTabClube1") {
      handleRecarregarDadosClube();
    }*/

    
  };

  const handleTabChangeHabitualidade = (key) => {
    handleRecarregarDadosHabitualidades();
    setActiveTabHabitualidade(key);
    if (key === "subTabHabitualidade2") {
      setCarregarUsuarioFormHabitualidade(!carregarUsuarioFormHabitualidade);
    }
  };

  const handleTabClick = (key) => {
    if (key === "1") {
      setRecarregarDadosInicio(!recarregarDadosInicio);
      setTabName("Início");
    }

    if (key === "2") {
      handleTabChangeHabitualidade("subTabHabitualidade1");
      setTabName("Habitualidade");
    }
    if (key === "3") {
      setTabName("Conta");
    }
    if (key === "4") {
      setTabName("Rodapé");
    }

    if(key === "6"){
      setTabName("Redes Sociais");
    }

    if(key === "7"){
      setTabName("Cabeçalho");
    }

    if (key === "5") {
      setTabName("Sair");
      const confirmed = window.confirm("Deseja sair?");
      if (confirmed) {
        window.localStorage.removeItem("accessToken");
        window.localStorage.removeItem("idUser");
        history.push("/");
        window.location.reload();
      } else {
        setActiveKey("1");
        setTabName("Início");
      }
    } else {
      setActiveKey(key);
    }


  };

  return (
    <Container>
      <PageHeader tabName={tabName} nomeLoja={nomeLoja} nomeUsuarioLogado={nomeUsuarioLogado}  />
      <Tabs
        activeKey={activeKey}
        tabBarGutter={20}
        centered
        styled={{ width: "100%" }}
        onTabClick={handleTabClick}
        tabBarStyle={{
          backgroundColor: "black",
          color: "white",
          activeTab: "#43e97b",
          margin: 0,
          position: "fixed",
          zIndex: 99,
          width: "100%",
          bottom: 0,
        }}
      >
        <TabPane
          tab={
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                fontSize: "12px",
              }}
            >

              <FontAwesomeIcon
                icon={fa1}
                style={{ width: "25px", height: "25px" }}
              />
               Seção
            </span>
          }
          key="1"
        >

          <Tabs
            activeKey={activeTabHome}
            tabBarGutter={20}
            centered
            styled={{ width: "100%" }}
            onTabClick={handleTabChangeHome}
            tabBarStyle={{
              backgroundColor: "black",
              color: "white",
              activeTab: "#43e97b",
              margin: 0,
              position: "fixed",
              width: "100%",
              top: "50px",
              zIndex: 99,
            }}
          >
            <TabPane tab="CORES" key="subTabHome1">           
{
//<CrudClube recarregarDados={recarregarDadosClube} arrayContatos={arrayContatos} setArrayContatos={setArrayContatos} pagination={pagination} setPagination={setPagination}></CrudClube>
}
              <Home recarregarDados={recarregarDadosInicio}></Home>
            </TabPane>
            <TabPane tab="TEXTOS" key="subTabHome2">
            <HomeTextos recarregarDados={recarregarDadosInicio}></HomeTextos>
            </TabPane>

            <TabPane tab="LINKTREE" key="subTabHome3">
            <EditarLinks recarregarDados={recarregarDadosInicio}></EditarLinks>
            </TabPane>


          </Tabs>


        </TabPane>





        <TabPane
          tab={
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                fontSize: "12px",
              }}
            >

              <FontAwesomeIcon
                icon={fa2}
                style={{ width: "25px", height: "25px" }}
              />
               Seção
            </span>
          }
          key="8"
        >

          <Tabs
            activeKey={activeTabHome}
            tabBarGutter={20}
            centered
            styled={{ width: "100%" }}
            onTabClick={handleTabChangeHome}
            tabBarStyle={{
              backgroundColor: "black",
              color: "white",
              activeTab: "#43e97b",
              margin: 0,
              position: "fixed",
              width: "100%",
              top: "50px",
              zIndex: 99,
            }}
          >
            <TabPane tab="CORES" key="subTabHome1">           
{
//<CrudClube recarregarDados={recarregarDadosClube} arrayContatos={arrayContatos} setArrayContatos={setArrayContatos} pagination={pagination} setPagination={setPagination}></CrudClube>
}
              <SecondSessionColors recarregarDados={recarregarDadosInicio}></SecondSessionColors>
            </TabPane>
            <TabPane tab="TEXTOS" key="subTabHome2">
            <SecondSessionContent recarregarDados={recarregarDadosInicio}></SecondSessionContent>
            </TabPane>
          </Tabs>


        </TabPane>





        <TabPane
          tab={
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                fontSize: "12px",
              }}
            >

              <FontAwesomeIcon
                icon={fa3}
                style={{ width: "25px", height: "25px" }}
              />
               Seção
            </span>
          }
          key="9"
        >

          <Tabs
            activeKey={activeTabHome}
            tabBarGutter={20}
            centered
            styled={{ width: "100%" }}
            onTabClick={handleTabChangeHome}
            tabBarStyle={{
              backgroundColor: "black",
              color: "white",
              activeTab: "#43e97b",
              margin: 0,
              position: "fixed",
              width: "100%",
              top: "50px",
              zIndex: 99,
            }}
          >
            <TabPane tab="CORES" key="subTabHome1">           
{
//<CrudClube recarregarDados={recarregarDadosClube} arrayContatos={arrayContatos} setArrayContatos={setArrayContatos} pagination={pagination} setPagination={setPagination}></CrudClube>
}
              <ThirdSessionColors recarregarDados={recarregarDadosInicio}></ThirdSessionColors>
            </TabPane>
            <TabPane tab="TEXTOS" key="subTabHome2">
            <ThirdSessionContents recarregarDados={recarregarDadosInicio}></ThirdSessionContents>
            </TabPane>
          </Tabs>


        </TabPane>

  

        <TabPane
          tab={
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                fontSize: "12px",
              }}
            >
              <FontAwesomeIcon
                 icon={faTurnDown}
                style={{ width: "25px", height: "25px" }}
              />
              Rodapé
            </span>
          }
          key="4"
        >
          <Tabs
            activeKey={activeTabClube}
            tabBarGutter={20}
            centered
            styled={{ width: "100%" }}
            onTabClick={handleTabChangeClube}
            tabBarStyle={{
              backgroundColor: "black",
              color: "white",
              activeTab: "#43e97b",
              margin: 0,
              position: "fixed",
              width: "100%",
              top: "50px",
              zIndex: 99,
            }}
          >
            <TabPane tab="CORES" key="subTabClube1">

            <RodapeCores></RodapeCores>

            </TabPane>


            <TabPane tab="CONTEUDO" key="subTabClube2">
              <ConteudoRodape></ConteudoRodape>
            </TabPane>
          </Tabs>
        </TabPane>





        <TabPane
          tab={
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                fontSize: "12px",
              }}
            >
              <FontAwesomeIcon
                 icon={faTurnUp}
                style={{ width: "25px", height: "25px" }}
              />
              Cabeçalho
            </span>
          }
          key="7"
        >
          <Tabs
            activeKey={activeTabClube}
            tabBarGutter={20}
            centered
            styled={{ width: "100%" }}
            onTabClick={handleTabChangeClube}
            tabBarStyle={{
              backgroundColor: "black",
              color: "white",
              activeTab: "#43e97b",
              margin: 0,
              position: "fixed",
              width: "100%",
              top: "50px",
              zIndex: 99,
            }}
          >
            <TabPane tab="CORES" key="subTabClube1">

            <HeaderColors></HeaderColors>

            </TabPane>


            <TabPane tab="CONTEUDO" key="subTabClube2">
              <HeaderLinks></HeaderLinks>
            </TabPane>
          </Tabs>
        </TabPane>





        <TabPane
          tab={
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                fontSize: "12px",
              }}
            >
              <FontAwesomeIcon
                icon={faThumbsUp}
                style={{ width: "25px", height: "25px" }}
              />
              Redes Sociais
            </span>
          }
          key="6"
        >


        <Tabs
            activeKey={activeTabRedes}
            tabBarGutter={20}
            centered
            styled={{ width: "100%" }}
            onTabClick={handleTabChangeRedes}
            tabBarStyle={{
              backgroundColor: "black",
              color: "white",
              activeTab: "#43e97b",
              margin: 0,
              position: "fixed",
              width: "100%",
              top: "50px",
              zIndex: 99,
            }}
          >
            <TabPane tab="PRIMEIRA SEÇÃO" key="subTabRedes1">
              {
                //<CrudClube recarregarDados={recarregarDadosClube} arrayContatos={arrayContatos} setArrayContatos={setArrayContatos} pagination={pagination} setPagination={setPagination}></CrudClube>
              }

<RedesPrimeiraSessao></RedesPrimeiraSessao>

                </TabPane>
            <TabPane tab="SEÇÃO RODAPÉ" key="subTabRedes2">

            <RedesRodape></RedesRodape>


            </TabPane>

            <TabPane tab="Meta Tags" key="subTabRedes3">

            <TagsForm></TagsForm>


            </TabPane>

            
          </Tabs>


          
        </TabPane>




        <TabPane
          tab={
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                fontSize: "12px",
              }}
            >
              <FontAwesomeIcon
                icon={faUser}
                style={{ width: "25px", height: "25px" }}
              />
              Conta
            </span>
          }
          key="3"
        >
          <Tabs
            activeKey={activeTabPerfil}
            tabBarGutter={20}
            centered
            styled={{ width: "100%" }}
            onTabClick={handleTabChangePerfil}
            tabBarStyle={{
              backgroundColor: "black",
              color: "white",
              activeTab: "#43e97b",
              margin: 0,
              position: "fixed",
              width: "100%",
              top: "50px",
              zIndex: 99,
            }}
          >
            <TabPane tab="VISUALIZAR" key="subTab1">
              <VisualizarPerfil
                onTabChange={handleTabChangePerfil}
                recarregarDados={recarregarDadosPerfil}
              ></VisualizarPerfil>
            </TabPane>
            <TabPane tab="REGISTRO" key="subTab2">
              <Perfil
                onTabChange={handleTabChangePerfil}
                setarReload={handleRecarregarDadosPerfil}
                recarregarFormPerfil={carregarPerfil}
              ></Perfil>
            </TabPane>
            <TabPane tab="ALTERAR SENHA" key="subTab3">
              <AlterarSenhaScreen
                onTabChange={handleTabChangePerfil}
              ></AlterarSenhaScreen>
            </TabPane>
          </Tabs>
        </TabPane>



        <TabPane
          tab={
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                fontSize: "12px",
              }}
            >
              <FontAwesomeIcon
                icon={faCircleInfo}
                style={{ width: "25px", height: "25px" }}
              />
              Sobre
            </span>
          }
          key="2"
        >
          <Sobre></Sobre>
        </TabPane>

        <TabPane
          tab={
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                fontSize: "12px",
              }}
            >
              <FontAwesomeIcon
                icon={faRightFromBracket}
                style={{ width: "25px", height: "25px" }}
              />
              Sair
            </span>
          }
          key="5"
        ></TabPane>

 
      </Tabs>

    </Container>
  );
};
const Container = styled.div`
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  padding: 0;
`;

const TabsContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: black;
  z-index: 1; /* Garante que as abas fiquem acima do conteúdo */
`;

const CentralizedText = styled.div`
  text-align: center;
  color: white;
  font-size: 14px;
  margin-top: 10px;
  width:'100%';
`;
export default TopTab;
